import { useState } from "react";
import JsonObject from "./JsonObject";
import LargeImage from "./LargeImage";

const JsonWriter = ({ data, title }) => {
  const [image, setImage] = useState(null);
  return (
    <div className="code-block">
      <div className="comment mb-4">{`#` + title}</div>
      {Array.isArray(data) ? (
        data.map(d => (
          <JsonObject
            key={d.name}
            object={d}
            handleImage={setImage}
          />
        ))
      ) : (
        <JsonObject object={data} />
      )}
      <LargeImage
        projectImage={image}
        resetImage={setImage}
      />
    </div>
  );
};

export default JsonWriter;
