import React from "react";
import ReactDOM from "react-dom/client";
import "animate.css";
import App from "./App";
import "./transitions.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./components/utils/constants";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: routes.map(route => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </React.StrictMode>
);
