import JsonWriter from "../helpers/JsonWriter";
import RowRight from "../helpers/RowRight";
import { experience } from "../utils/data";

const Experience = () => {
  return (
    <RowRight icon="experience">
      <JsonWriter
        data={experience}
        title="experience"
      />
    </RowRight>
  );
};

export default Experience;
