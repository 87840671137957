const JsonObject = ({ object, handleImage }) => {
  const max = 50;
  const step = 10;
  let current = "";
  let rest = "";
  return (
    <>
      <div className="brace">{`{`}</div>
      {Object.keys(object).map(key => {
        let label = object[key];
        if (Array.isArray(label)) label = label.join(" | ");
        if (key === "level") {
          current = parseInt(step * +label);
          label = "|".repeat(current);
          rest = "|".repeat(max - current);
        }
        return (
          <div
            className="object-field d-flex ms-4"
            key={key}
          >
            <div className="object-key">{key}: </div>

            <div className="object-value ms-2 d-flex">
              {key === "level" && (
                <div className="d-flex">
                  <div className="text-success">{label}</div>
                  <div style={{ color: "white" }}>{rest}</div>
                </div>
              )}
              {key === "image" && (
                <div className="d-flex">
                  <div>
                    <img
                      className="project-image"
                      src={`/images/projects/thumbs/${object[key]}.PNG`}
                      alt={object["key"]}
                      onClick={() => handleImage(object[key])}
                    />
                  </div>
                </div>
              )}
              {!["level", "image"].includes(key) && label}
            </div>
          </div>
        );
      })}
      <div className="brace">
        {`}`}
        <span className="text-secondary">,</span>
      </div>
    </>
  );
};

export default JsonObject;
