const RowLeft = ({ children }) => {
  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <img
          className="logo-image"
          src="/images/logo400.png"
          alt="Logo"
        />
      </div>
      <div className="col-lg-8 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
        {children}
      </div>
    </div>
  );
};

export default RowLeft;
