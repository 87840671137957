const RowRight = ({ icon, children }) => {
  return (
    <div className="row my-4">
      <div className="col-lg-8 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
        <div
          style={{
            width: "100%",
            maxHeight: "50vh",
            overflowY: "scroll",
          }}
        >
          {children}
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
        <img
          src={`images/${icon}.svg`}
          alt="Contact"
          width={"50%"}
          className="svg-icon"
        />
      </div>
    </div>
  );
};

export default RowRight;
