import RowRight from "../helpers/RowRight";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <RowRight icon="contact">
      <ContactForm />
    </RowRight>
  );
};

export default Contact;
