import { useEffect, useRef, useState } from "react";

const emptyContact = {
  email: "",
  subject: "",
  message: "",
};
const ContactForm = () => {
  const firstField = useRef();
  const [contact, setContact] = useState({ ...emptyContact });
  const onSubmit = e => {
    e.preventDefault();
    setContact({ ...emptyContact });
    firstField.current.focus();
  };
  const setValue = e => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    firstField.current.focus();
  }, []);
  return (
    <div className="code-block">
      <form onSubmit={onSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="comment mb-4">{`# contact form`}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-end">
              your@<span className="object-key">email</span>
            </div>
            <div className="col-md-8 cli-input-container ps-2 d-flex">
              <input
                ref={firstField}
                name="email"
                type="email"
                className="cli-input w-100"
                placeholder="email@email.com"
                value={contact.email}
                onChange={setValue}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-end">
              message@<span className="object-key">subject</span>
            </div>
            <div className="col-md-8 cli-input-container ps-2 d-flex">
              <input
                name="subject"
                type="text"
                className="cli-input w-100"
                placeholder="Subject ..."
                value={contact.subject}
                onChange={setValue}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-end">
              message@<span className="object-key">content</span>
            </div>
            <div className="col-md-8 cli-input-container ps-2 d-flex">
              <textarea
                name="message"
                className="cli-input w-100"
                placeholder="Content ..."
                rows={4}
                value={contact.message}
                onChange={setValue}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <button className="btn btn-outline-secondary">Send</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
