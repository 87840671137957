import FuzzySet from "fuzzyset.js";
import { utterances, answers, alternatives, excludedWords } from "./constants";

const compare = (utterancesArray, answersArray, string) => {
  const index = utterancesArray.findIndex(u => u.includes(string));
  if (index > -1) return answersArray[index][0];
  return false;
};

const prepareText = text => {
  text = text.toLowerCase().replace(/[^\w\s\d]/gi, "");
  let words = text.split(" ");
  words = words.filter(word => !excludedWords.includes(word));
  text = words.join(" ");
  text = text.trim();
  return text;
};

export const resolveRoute = input => {
  let route;
  let text = prepareText(input);
  if (compare(utterances, answers, text)) {
    route = compare(utterances, answers, text);
  } else {
    let index = null;
    utterances.forEach((u, i) => {
      const fs = FuzzySet(u);
      const result = fs.get(text);
      if (result && result[0][0] > 0.4 && result[0][0] > index) index = i;
    });
    if (index) route = answers[index][0];
    else route = alternatives[0];
  }
  return route;
};
