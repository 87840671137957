export const projects = [
  {
    name: "GulfTravelBosna",
    company: "Freelancing",
    year: 2009,
    domain: "Rent-a-car",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii2", "Bootstrap", "MySQL"],
    description:
      "Full rent-a-car solution for online-bookings, reservations, invoices, handovers, etc.",
    image: "gulftravelbosna",
  },
  {
    name: "MSEAP",
    company: "Innovative Business Solutions",
    year: 2014,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii1", "Bootstrap", "MySQL"],
    description:
      "As part of “Majid for Community Development” ongoing efforts to enhance productivity and quality of work, Majid Society employed Innovative Business Solutions (IBS) to design and develop a complete workflow automation cloud-based solution (Majed Society Environment Automation Platform – MSEAP) which shall aid to achieve desired goal. MSEAP is a web-based solution specifically tailored towards Majid Society’s operational environment in managing it’s major programs.",
    image: "mseap",
  },
  {
    name: "HR",
    company: "Innovative Business Solutions",
    year: 2015,
    domain: "HR",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii1", "Bootstrap", "MySQL"],
    description: "HR Solution for Majid Society",
    image: "mseap",
  },
  {
    name: "Meetings",
    company: "Innovative Business Solutions",
    year: 2015,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii1", "Bootstrap", "MySQL"],
    description:
      "Meetings service for Majid Society, fully integrated with MSEAP",
    image: "meetings",
  },
  {
    name: "EFQM",
    company: "Innovative Business Solutions",
    year: 2015,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii1", "Bootstrap", "MySQL"],
    description:
      "As na Majid Society effort to improve their performance IBS has developed EFQM solution. The EFQM Model structure is based on the simple but powerful logic of asking three questions: WHY, HOW, WHAT.",
    image: "qa",
  },
  {
    name: "Reports",
    company: "Innovative Business Solutions",
    year: 2016,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii1", "Bootstrap", "MySQL"],
    description:
      "Module for tailoring custom reports from various components (headers, tables, charts ...) for Majid Society",
    image: "meetings",
  },
  {
    name: "Phisical Archive Tracker",
    company: "Innovative Business Solutions",
    year: 2017,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "Yii2", "Bootstrap", "MySQL"],
    description:
      "Keeping track of phisical archive of variuos files and uploding files to the system.",
    image: "pat",
  },
  {
    name: "Elevators",
    company: "Bright Solutions",
    year: 2017,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap", "MySQL"],
    description:
      "Bright solutions was official partner of Europrost in Bosnia and Herzegovina. We developed a system for tracking the workflow which inclues: Client request, Configuration of elevators requirements, Manufacturer Order request, Offer price calculator, Client offer, Process of elevators installation.",
    image: "elevators",
  },
  {
    name: "Elevators Service",
    company: "Bright Solutions",
    year: 2017,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap", "MySQL"],
    description: "After Sales service for Elevators App",
    image: "elevators_services",
  },
  {
    name: "Elevators HR",
    company: "Bright Solutions",
    year: 2017,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap", "MySQL"],
    description: "HR module for Bright solutions Elevators App",
    image: "elevators_hr",
  },
  {
    name: "PTT - Project - Task Tracker",
    company: "INT Global",
    year: 2017,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap", "MySQL"],
    description:
      "Web App for tracking projects and tasks for project in Germany, handling LV, Employees perormance, project progress ...",
    image: "ppt",
  },
  {
    name: "PTMS - Project - Task Management System",
    company: "INT BH",
    year: 2018,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap", "MySQL"],
    description:
      "Web App for tracking projects and tasks With workflows for Prepration and Implementation Phases. Calendar of tasks, Notes, Scheduled tasks, Notification System ...",
    image: "ptms",
  },
  {
    name: "Compliance, Auditing and Risk Management",
    company: "Private Client",
    year: 2018,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 4", "MySQL"],
    description:
      "Web App folowing a process of Compliance, Auditing and Risk Management for companies.",
    image: "complience",
  },
  {
    name: "Eman - Expence Manager",
    company: "Private",
    year: 2020,
    domain: "Finance",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 4", "MySQL"],
    description: "Web App keeping track of personal finance.",
    image: "eman",
  },
  {
    name: "Appstracker App",
    company: "Private",
    year: 2022,
    domain: "Project meanagment",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 5", "MySQL"],
    description:
      "Web App for tracking projects and issues in Kanban style. Backlog, ToDo, Progress, Done. Drag and drop, Project, task description. Files upload. Tagging files per issue. Changes log...",
    image: "appstracker_app",
  },
  {
    name: "Besplatno.ba",
    company: "Private",
    year: 2021,
    domain: "Tool",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 4", "MySQL"],
    description: "Web App for publishing things for gift.",
    image: "besplatno",
  },
  {
    name: "Pripreme",
    company: "Private",
    year: 2021,
    domain: "Education",
    tech: [
      "PHP",
      "Python",
      "HTML",
      "CSS",
      "JS",
      "VueJS",
      "Yii2",
      "Bootstrap 4",
      "MySQL",
      "ChatGPT",
    ],
    description:
      "Web App for helping teachers to create teaching preparation, saving soft copy as a PDF file, printing, sharing ...",
    image: "priprema_1",
  },
  {
    name: "Sound manager",
    company: "Private",
    year: 2022,
    domain: "Multimedia",
    tech: [
      "PHP",
      "Python",
      "HTML",
      "CSS",
      "JS",
      "VueJS",
      "Yii2",
      "Bootstrap 4",
      "MySQL",
    ],
    description:
      "Download Youtube video and save as an audio file. Managing saved files. Tags, Playlists, Search ...",
    image: "music",
  },
  {
    name: "Delivery",
    company: "Private Client",
    year: 2023,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 4", "MySQL"],
    description:
      "Web App for sending packages and Tracking of delivery process.",
    image: "delivery_package",
  },
  {
    name: "Whole sale solution",
    company: "Private Client",
    year: 2023,
    domain: "B2B",
    tech: ["PHP", "HTML", "CSS", "JS", "VueJS", "Yii2", "Bootstrap 4", "MySQL"],
    description:
      "Web App for Whole sale of products. One of key features is the ability to offer custom pricing on selected products for selected customers. This level of customization helps to establish strong, long-lasting relationships with wholesale customers and more.",
    image: "b2b_1",
  },
  {
    name: "Quiz",
    company: "Private Client",
    year: 2023,
    domain: "Education",
    tech: [
      "PHP",
      "Python",
      "HTML",
      "CSS",
      "JS",
      "VueJS",
      "Yii2",
      "Bootstrap 4",
      "MySQL",
      "ChatGPT",
    ],
    description:
      "Web App for Whole creating and generating questions(essei, singe of multi choice, true/false ...). Creating custom Quizes (dificulty level, number of questions per category, for targeted audience ...). Quzies can be run by time scheduling or moderated by moderator.",
    image: "quiz_start",
  },
  {
    name: "Multiple services",
    company: "Freelance",
    year: 2014,
    domain: "Various",
    tech: [
      "Visual Basic",
      "MS Access",
      "Python",
      "Django",
      "PHP",
      "Laravel",
      "Yii2",
      "Flutter",
      "Java",
      "Spring Boot",
      "Html",
      "CSS",
      "JS",
      "React",
      "Angular",
      "MSSQL",
      "postgreSQL",
      "Firebase",
      "MongoDB",
    ],
    description: "Multiple services. 2014 - UTD",
    image: "noimage",
  },
  {
    name: "Voice",
    company: "Infobip",
    year: 2020,
    domain: "VoIP",
    tech: [
      "Java",
      "Spring Boot",
      "Html",
      "CSS",
      "JS",
      "React",
      "Angular",
      "MSSQL",
    ],
    description: "Multiple services. 9/2020 - 12/2022",
    image: "noimage",
  },
  {
    name: "Real estate",
    company: "Endava",
    year: 2022,
    domain: "B2B",
    tech: [".NET", "MSSQL", "AWS", "CircelCI"],
    description: "Real estate. 12/2022 - UTD",
    image: "noimage",
  },
];
