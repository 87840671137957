import { createRef } from "react";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Experience from "../pages/Experience";
import Projects from "../pages/Projects";
import Skills from "../pages/Skills";

export const home = ["/", "home"];
export const mainRoutes = ["/", "about", "contact", "skills", "experience"];

export const routes = [
  { path: "/", name: "Home", element: <About />, nodeRef: createRef() },
  { path: "/about", name: "About", element: <About />, nodeRef: createRef() },
  {
    path: "/contact",
    name: "Contact",
    element: <Contact />,
    nodeRef: createRef(),
  },
  {
    path: "/skills",
    name: "Skills",
    element: <Skills />,
    nodeRef: createRef(),
  },
  {
    path: "/experience",
    name: "Experience",
    element: <Experience />,
    nodeRef: createRef(),
  },
  {
    path: "/projects",
    name: "Projects",
    element: <Projects />,
    nodeRef: createRef(),
  },
];

export const utterances = [
  ["/", "home", "reset", "open home page", "go to start", "restart"],
  [
    "about",
    "who are you",
    "what about you",
    "can you tell me about You",
    "tell me something about You",
  ],
  [
    "contact",
    "send message",
    "contact form",
    "what is your email",
    "how can i contact You",
  ],
  [
    "skills",
    "php",
    "css",
    "yii",
    "java",
    "node",
    "html",
    "react",
    "backend",
    "frontend",
    "database",
    "tech stack",
    "javascript",
    "spring boot",
    "programming",
    "what are your skills",
    "programming languages",
    "what is your tech stack",
    "what programming languages are You familiar with",
  ],
  [
    "experience",
    "work",
    "companies",
    "previous work",
    "previous companies",
    "what is You experience",
    "what have you been doing",
    "where have You been working",
  ],
  [
    "projects",
    "service",
    "application",
    "your projects",
    "what do You do",
    "what are you doing",
    "what are you working on",
    "on what projects did you work",
  ],
];

export const answers = [
  ["/"],
  ["about"],
  ["contact"],
  ["skills"],
  ["experience"],
  ["projects"],
];

export const excludedWords = [
  "what",
  "that",
  "is",
  "a",
  "the",
  "tell",
  "me",
  "your",
];

export const alternatives = ["/"];
