import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resolveRoute } from "../utils/RouteResolver";
const Cli = () => {
  const navigate = useNavigate();
  const [command, setCommand] = useState("");
  const inputReference = useRef(null);

  const onChange = e => {
    setCommand(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      navigate(resolveRoute(command));
      setCommand("");
    }
  };

  useEffect(() => {
    inputReference.current.focus();
  }, []);

  return (
    <div className="fixed-bottom w-100">
      <div className="container cli d-flex align-items-center">
        <div className="col-12 cli-input-container ps-2 d-flex">
          <input
            type="text"
            className="cli-input w-100"
            ref={inputReference}
            placeholder="Try writing 'skills', 'projects', 'experience' or 'contact'"
            value={command}
            onChange={onChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </div>
  );
};

export default Cli;
