import React from "react";
import { useLocation, useOutlet } from "react-router-dom";

import Cli from "./components/helpers/Cli";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { routes } from "./components/utils/constants";

function App() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } =
    routes.find(route => route.path === location.pathname) ?? {};
  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames="fade"
          addEndListener={done => {
            nodeRef.current.addEventListener("transitionend", done, false);
          }}
          unmountOnExit
        >
          <div
            ref={nodeRef}
            className="container main-container"
          >
            <div className="wrapper d-flex flex-column justify-content-center">
              {currentOutlet}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Cli />
    </>
  );
}

export default App;
