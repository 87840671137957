import { useEffect, useState } from "react";
const LargeImage = ({ projectImage, resetImage }) => {
  const [show, setShow] = useState(false);

  const handleImage = () => {
    setShow(!show);
    resetImage(null);
  };
  useEffect(() => {
    if (projectImage) setShow(true);
  }, [projectImage]);
  return (
    <>
      {show && (
        <div
          className="large-image"
          onClick={handleImage}
        >
          <img
            className="animate__animated animate__zoomIn"
            src={`images/projects/${projectImage}.PNG`}
            alt={projectImage}
          />
        </div>
      )}
    </>
  );
};

export default LargeImage;
