export const personalData = {
  firstName: "Selvedin",
  lastName: "Haskic",
  country: "Bosna i Hercegovina",
  city: "Vitez",
  profession: "Software Developer",
  hobies: "Swimming 🏊",
};

export const programmingLanguages = [
  {
    name: "JavaScript",
    yearsOfExperience: 10,
    level: 4,
  },
  {
    name: "PHP",
    yearsOfExperience: 15,
    level: 5,
  },
  {
    name: "Java",
    yearsOfExperience: 3,
    level: 3.5,
  },
  {
    name: "Python",
    yearsOfExperience: 5,
    level: 3.5,
  },
  {
    name: "NodeJS",
    yearsOfExperience: 5,
    level: 4,
  },
];

export const jsFrameworks = [
  {
    name: "React",
    yearsOfExperience: 6,
    level: 4.5,
  },
  {
    name: "VueJS",
    yearsOfExperience: 7,
    level: 4.5,
  },
  {
    name: "Angualar",
    yearsOfExperience: 3,
    level: 3.5,
  },
];

export const frameworks = [
  {
    name: "Yii2",
    yearsOfExperience: 17,
    level: 4.5,
  },
  {
    name: "SpringBoot",
    yearsOfExperience: 3,
    level: 4,
  },
  {
    name: "Django",
    yearsOfExperience: 2,
    level: 3.5,
  },
  {
    name: "Laravel",
    yearsOfExperience: 3,
    level: 3.5,
  },
];

export const experience = [
  {
    name: "Media Pro d.o.o.",
    startYear: 2003,
    endYear: 2005,
    domain: "Publishing",
  },
  {
    name: "Primary School Vitez",
    startYear: 2006,
    endYear: 2014,
    domain: "Education",
  },
  {
    name: "Secondary School Vitez",
    startYear: 2006,
    endYear: 2014,
    domain: "Education",
  },
  {
    name: "Innovative Business Solutions (KSA)",
    startYear: 2014,
    endYear: 2017,
    domain: "Software Development",
  },
  {
    name: "Bright Solutions (KSA)",
    startYear: 2017,
    endYear: 2019,
    domain: "Software Development",
  },
  {
    name: "Infobip",
    startYear: 2019,
    endYear: 2022,
    domain: "Software Development",
  },
  {
    name: "Endava",
    startYear: 2022,
    endYear: new Date().getFullYear(),
    domain: "Software Development",
  },
  {
    name: "Freelancing",
    startYear: 2005,
    endYear: new Date().getFullYear(),
    domain:
      "Web Design, Sowfware Design, Software Development, Graphic Design, 3D Design, Publishing ...",
  },
];
