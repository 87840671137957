import JsonWriter from "../helpers/JsonWriter";
import RowRight from "../helpers/RowRight";
import { projects } from "../utils/projects";

const Projects = () => {
  return (
    <RowRight icon="projects">
      <JsonWriter
        data={projects}
        title="tech projects"
      />
    </RowRight>
  );
};

export default Projects;
