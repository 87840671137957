import RowLeft from "../helpers/RowLeft";
import JsonWriter from "../helpers/JsonWriter";
import { personalData } from "../utils/data";

const About = () => {
  return (
    <RowLeft>
      <JsonWriter
        data={personalData}
        title="personal info"
      />
    </RowLeft>
  );
};

export default About;
