import JsonWriter from "../helpers/JsonWriter";
import RowRight from "../helpers/RowRight";
import { programmingLanguages, jsFrameworks, frameworks } from "../utils/data";

const Skills = () => {
  const all = programmingLanguages.concat(jsFrameworks, frameworks);
  return (
    <RowRight icon="skills">
      <JsonWriter
        data={all}
        title="tech skills"
      />
    </RowRight>
  );
};

export default Skills;
